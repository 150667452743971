import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/en';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/support/*.en.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            lead
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const Support = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title="Support - Conditions of sale, transport and after-sales service."
        description="Order online on our site, to benefit of efficient and favorable services."
        pathname="/en/support/"
        keywords={['snowboard', 'boards', 'polyvalence']}
      />
      <div className="supportPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5">
              <p className="lead">
                Since 2011 we had no feedback or manufacturing defect observed :)
                Since 2011, our snowboards are made in Poland in the best ski and
                snowboard factory in Europe.
              </p>
              <p className="lead">
                You buy your snowboard on our site, we offer you a 2 year guarantee
                and you benefit from a personalized after sales service. Contact us
                if necessary to the contacts indicated on the site.
              </p>
              <p className="lead">
                During this
                period of 2 years, if a manufacturing defect is observed we change
                your board. In case of minimal defect and if possibility, we repair
                your board. If necessary we change the board.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 content">
              {posts.map(({ node: post }) => (
                <Fragment key={post.id}>
                  <h2 className="mb-3">{post.frontmatter.title}</h2>
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                  </p>
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
